<!-- 学员注册 -->
<template>
  <div class="login-cont">
    <div class="login-logo">
      <div class="login-logo-img"><img src="../../../public/static/logo.png" /></div>
      <span class="login-logo-text">{{websiteInfo.gongzhonghao}}</span>
    </div>
    <div class="reg-cont">
      <div class="reg-cont-cont-cont">
        <div class="reg-cont-tag">
          <div class="reg-cont-tag-item add-cursor" :class="[type==0?'reg-cont-tag-item-active':'']"
            @click="selectTypeBtn" data-type="0">学员用户</div>
          <div class="reg-cont-tag-item add-cursor" :class="[type==1?'reg-cont-tag-item-active':'']"
            @click="selectTypeBtn" data-type="1">企业用户</div>
        </div>
        <div class="steps">
          <div class="steps-item add-cursor" :class="[item.step_ative!=''?'steps-item-active':'']"
            v-for="item in stepsList">{{item.label}}</div>
        </div>
        <!-- 注册协议 -->
        <div class="reg-agreement-cont" v-if="step == 0">
          <div class="reg-agreement-title">请您仔细阅读</div>
          <div class="reg-agreement-text" v-html="websiteInfo.register_agreement"></div>
          <div class="reg-agreement-btn">
            <div class="reg-agreement-btn-disagree add-cursor" @click="noAgree()">不同意</div>
            <div class="reg-agreement-btn-agree add-cursor" :data-step="0" @click="next($event)">同意协议</div>
          </div>
        </div>
        <!-- 基本信息 -->
        <div class="reg-user-info" v-if="step == 1">
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">用户名</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="username"
              name="username" placeholder="请输入用户名">
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">手机号码</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="mobile"
              name="mobile"  placeholder="请输入手机号码">
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">验证码</div>
            <input class="reg-form-cont-input-yzm"  placeholder-class="reg-form-cont-placeholder"
              v-model="code" name="code" placeholder="请输入验证码">
            <div class="reg-user-info-form-yzm add-cursor" v-if="showPhoneCode" @click="getPhoneCode()">发送验证码</div>
            <div class="reg-user-info-form-yzm" v-else>{{ count }}</div>
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">登录密码</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="password"
              v-model="password" name="password" placeholder="请输入登录密码">
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">确认密码</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="password"
              v-model="repassword" name="repassword" placeholder="需与登录密码保持一致">
          </div>
          <div class="reg-user-info-btn">
            <div class="reg-back-btn add-cursor" :data-step="1" @click="back($event)">上一步</div>
            <div class="reg-agreement-btn-agree add-cursor" :data-step="1" @click="baseNext($event)">下一步</div>
          </div>
        </div>

        <!-- 完善注册信息 -->
        <div class="reg-perfect-info" v-if="step == 2 && type == 0">
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">姓名</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="name" name="name"
              placeholder="请输入姓名">
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">性别</div>
            <el-select v-model="sex" placeholder="请选择" style="margin:0.5rem 0;width:50%;">
              <el-option v-for="item in sexArr" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">身份证号码</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="text"
              v-model="id_card" name="id_card" placeholder="请输入身份证号码">
          </div>
          <div class="reg-user-info-form">
            <div class="reg-perfect-info-form-label">邮箱</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="text" v-model="email"
              name="email" placeholder="邮箱可用于账号登录">
          </div>
          <div class="reg-user-info-form">
            <div class="reg-perfect-info-form-label">工作单位</div>
            <input class="reg-form-cont-input tip" placeholder-class="reg-form-cont-placeholder" type="text"
              v-model="enterprise_name" name="enterprise_name" @blur="inputEnterpriseBtn" placeholder="请输入或选择您的工作单位">
			  <div class="recruit-txt">{{enterpriseTxt}}</div>
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">学历</div>
            <el-select v-model="education_cate_id" placeholder="请选择" style="margin:0.5rem 0;width:50%;">
              <el-option v-for="item in educationCateArr" :key="item.education_cate_id" :label="item.name"
                :value="item.education_cate_id">
              </el-option>
            </el-select>
          </div>
		<div class="reg-user-info-form">
		  <div class="reg-perfect-info-form-label">招生码</div>
		  <input class="reg-form-cont-input tip" placeholder-class="reg-form-cont-placeholder" type="text"
			v-model="recruitcode" name="recruitcode" @blur="inputRecruitCodeBtn" placeholder="请输入招生老师的招生码">
			<div class="recruit-txt">{{recruitTxt}}</div>
		</div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">个人照片</div>
            <div class="reg-user-info-form-upbox">
              <el-upload class="reg-user-info-uploader" :action="uploadImg" v-loading.fullscreen.lock="loading"
                element-loading-text="拼命上传中" element-loading-background="rgba(0,0,0,0.1)" :show-file-list="false"
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="headimg" :src="headimg" class="reg-user-info-uploader-img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="reg-user-info-master"> *请上传1寸免冠白底证件照，否则将影响证书办理，后果自负！(仅支持上传JPG、PNG格式，大小为200KB内)</div>
            </div>
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">身份证正反面</div>
            <div class="reg-user-info-form-upbox">
              <el-upload class="reg-user-info-uploader" :action="uploadImg" v-loading.fullscreen.lock="loading"
                element-loading-text="拼命上传中" element-loading-background="rgba(0,0,0,0.1)" :show-file-list="false"
                :on-success="handleIdPhoto1Success" :before-upload="beforeAvatarUpload">
                <img v-if="id_photo1" :src="id_photo1" class="reg-user-info-uploader-img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-upload class="reg-user-info-uploader" :action="uploadImg" v-loading.fullscreen.lock="loading"
                element-loading-text="拼命上传中" element-loading-background="rgba(0,0,0,0.1)" :show-file-list="false"
                :on-success="handleIdPhoto2Success" :before-upload="beforeAvatarUpload">
                <img v-if="id_photo2" :src="id_photo2" class="reg-user-info-uploader-img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="reg-user-info-master"> *请上传1寸免冠白底证件照，否则将影响证书办理，后果自负！(仅支持上传JPG、PNG格式，大小为200KB内)</div>
            </div>
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">毕业证扫描件</div>
            <div class="reg-user-info-form-upbox">
              <el-upload class="reg-user-info-uploader" :action="uploadImg" v-loading.fullscreen.lock="loading"
                element-loading-text="拼命上传中" element-loading-background="rgba(0,0,0,0.1)" :show-file-list="false"
                :on-success="handleDiplomaPhotoSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="diploma_photo" :src="diploma_photo" class="reg-user-info-uploader-img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="reg-user-info-master"> *请上传毕业证扫描件，仅支持上传JPG、PNG格式，大小为500KB内</div>
            </div>
          </div>
          <div class="reg-user-info-btn reg-perfect-info-btn">
            <div class="reg-back-btn add-cursor" :data-step="2" @click="back($event)">上一步</div>
            <div class="reg-agreement-btn-agree add-cursor" :data-step="2" @click="perfectNext($event)">提交资料</div>
          </div>
        </div>

        <!-- 企业注册信息 -->
        <div class="reg-perfect-info" v-if="step == 2 && type ==1 ">
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">企业名称</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="enterprise_name" name="enterprise_name"
              placeholder="请输入企业名称">
          </div>
         <!-- <div class="reg-user-info-form">
           <div class="reg-user-info-form-label">统一社会信用代码</div>
           <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="credit_code" name="credit_code"
             placeholder="请输入统一社会信用代码">
         </div> -->
         <div class="reg-user-info-form">
           <div class="reg-user-info-form-label">企业类型</div>
           <el-select v-model="enterprise_type_id1" placeholder="请选择" style="margin:0.5rem 0;width:50%;" @change="changeEnterprisetype($event)">
             <el-option v-for="item in enterpriseTypeArr1" :key="item.id" :label="item.name" :value="item.id">
             </el-option>
           </el-select>
         </div>
         <div class="reg-user-info-form">
           <div class="reg-user-info-form-label">企业二级分类</div>
           <el-select v-model="enterprise_type_id2" placeholder="请选择" style="margin:0.5rem 0;width:50%;">
             <el-option v-for="item in enterpriseTypeArr2" :key="item.id" :label="item.name" :value="item.id">
             </el-option>
           </el-select>
         </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">企业法人</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="text"
              v-model="contacts" name="contacts" placeholder="请输入企业法人">
          </div>
		  <div class="reg-user-info-form">
		    <div class="reg-user-info-form-label">法人电话</div>
		    <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="text"
		      v-model="tel" name="tel" placeholder="请输入法人电话">
		  </div>
		  <div class="reg-user-info-form">
		    <div class="reg-user-info-form-label">单位电话</div>
		    <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="text"
		      v-model="company_tel" name="company_tel" placeholder="请输入单位电话">
		  </div>
          <div class="reg-user-info-form">
            <div class="reg-perfect-info-form-label">邮箱</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="text" v-model="email"
              name="email" placeholder="邮箱可用于账号登录">
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">企业地址</div>
            <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="address" name="address"
              placeholder="请输入您的企业地址">
          </div>
         <div class="reg-user-info-form">
           <div class="reg-perfect-info-form-label">纳税人识别号</div>
           <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" type="text"
             v-model="taxpayer_number" name="taxpayer_number" placeholder="请输入您的纳税人识别号">
         </div>
		 <div class="reg-user-info-form">
		   <div class="reg-perfect-info-form-label">招生码</div>
		   <input class="reg-form-cont-input tip" placeholder-class="reg-form-cont-placeholder" type="text"
		 	v-model="recruitcode" name="recruitcode" @blur="inputRecruitCodeBtn" placeholder="请输入招生老师的招生码">
		 	<div class="recruit-txt">{{recruitTxt}}</div>
		 </div>
         <div class="reg-user-info-form">
           <div class="reg-user-info-form-label">开户行</div>
           <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="bank_deposit" name="bank_deposit"
             placeholder="请输入您的开户行">
         </div>
         <div class="reg-user-info-form">
           <div class="reg-user-info-form-label">银行账号</div>
           <input class="reg-form-cont-input" placeholder-class="reg-form-cont-placeholder" v-model="bank_number" name="bank_number"
             placeholder="请输入您的银行账号">
         </div>
          <div class="reg-user-info-form">
            <div class="reg-perfect-info-form-label">企业LOGO</div>
            <div class="reg-user-info-form-upbox">
              <el-upload class="reg-user-info-uploader" :action="uploadImg" v-loading.fullscreen.lock="loading"
                element-loading-text="拼命上传中" element-loading-background="rgba(0,0,0,0.1)" :show-file-list="false"
                :on-success="logoSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="logo" :src="logo" class="reg-user-info-uploader-img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div class="reg-user-info-master">*企业LOGO的尺寸大小建议为160px*120px，文件格式为jpg或jpeg的图片</div>
            </div>
          </div>
          <div class="reg-user-info-form">
            <div class="reg-user-info-form-label">营业执照扫描件</div>
            <div class="reg-user-info-form-upbox">
              <el-upload class="reg-user-info-uploader" :action="uploadImg" v-loading.fullscreen.lock="loading"
                element-loading-text="拼命上传中" element-loading-background="rgba(0,0,0,0.1)" :show-file-list="false"
                :on-success="licenseSuccess" :before-upload="beforeAvatarUpload">
                <img v-if="license" :src="license" class="reg-user-info-uploader-img">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>

              <div class="reg-user-info-master"> *企业名称必须与单位营业执照中的名称一致</div>
            </div>
          </div>

          <div class="reg-user-info-btn reg-perfect-info-btn">
            <div class="reg-back-btn add-cursor" :data-step="2" @click="back($event)">上一步</div>
            <div class="reg-agreement-btn-agree add-cursor" :data-step="2" @click="perfectContactsNext($event)">提交资料</div>
          </div>
        </div>

        <!-- 提交审核成功 -->
        <div v-if="step == 3">
          <div class="reg-submit-text">{{mobile}}，恭喜您注册成功</div>
          <div class="reg-submit-btn add-cursor" :data-step="3" @click="toLogin()">去登录</div>
          <div class="reg-submit-ercode">
            <div>您还可以扫码关注平台微信公众号，及时了解最新培训注册动态信息</div>
            <img src="../../../public/static/qrcode.jpg" class="reg-submit-ercode-img" />
          </div>
        </div>

      </div>
    </div>
    <div class="login-form-cont-copyright">{{websiteInfo.copyright}} | <a href="https://beian.miit.gov.cn/"
        target="_blank">{{websiteInfo.icp}}</a>
    </div>
  </div>
</template>

<script>
  import interfaces from '../../../utils/interface.js'

  export default {
    components: {},
    data() {
      return {
        educationCateArr: [], //学历类别
        type: 0, //注册类型
        step: 0, //注册步骤
        stepsList: [{
            id: '0',
            label: "1、注册协议",
            step_ative: '1'
          },
          {
            id: '1',
            label: "2、用户基本信息",
            step_ative: ''
          },
          {
            id: '2',
            label: "3、完善注册信息",
            step_ative: ''
          },
          {
            id: '3',
            label: "4、提交审核通过",
            step_ative: ''
          },
        ],
        websiteInfo: [], //配置信息
        timer: null, //定时器的开关
        showPhoneCode: true, //获取成功验证码后倒计时跟获取不成功后没有倒计时按钮的开关
        count: "", //倒计时
        username: '', //用户名
        mobile: '', //手机号
        code: '', //验证码
        password: '', //密码
        repassword: '', //确认密码
        sexArr: [{
          value: '0',
          label: '男'
        }, {
          value: '1',
          label: '女'
        }],
        enterpriseypeTIdArr: [{
          value: '0',
          label: '顶级企业分类'
        }, {
          value: '1',
          label: '上级企业分类'
        }],
		recruitTxt:'',
		enterpriseTxt:'',
				enterpriseTypeArr1:[],
				enterpriseTypeArr2:[],
        //学员注册信息
        name: '', //姓名
        sex: '', //性别
        id_card: '', //身份证
        email: '', //邮箱
        enterprise_name: '', //工作单位
				recruitcode:'',
        education_cate_id: '', //学历
        headimg: '', //个人照片、头像
        id_photo1: '', //身份证正面
        id_photo2: '', //身份证反面
        diploma_photo: '', //毕业证件照
        //企业信息
        taxpayer_number: '', //	纳税人识别号
        address: '', //企业地址
        enterprise_type_id1: '', //	企业类型1
        enterprise_type_id2: '', //	企业类型2
        contacts: '', //企业联系人
				tel:'',//联系电话
				company_tel:'',//单位电话
        credit_code: '', //社会统一信用代码
        license: '', //营业执照扫描件
        logo: '', //企业logo
        bank_deposit:'', //开户行
        bank_number:'', //	银行号
        //控制上传loading
        loading: false,
        uploadImg: interfaces.uploadImages, //上传图片接口地址
        enterprisetype:[],
		
      };
    },
    created() {
      let that = this;
      that.getEducationCate();
      that.getWebsiteInfo();
	  that.getEnterprisetype1();
    },
    methods: {
      changeEnterprisetype(value){
        let that = this;
		
        that.getEnterprisetype2(value);
      },
	  inputRecruitCodeBtn(){
		  let that = this;
		  that.getHasRecruit();
	  },
	  inputEnterpriseBtn(){
		  let that = this;
		  that.getHasEnterprise();
	  },
      // 企业顶级分类
      getEnterprisetype1(){
        let that = this;
        let param = {
          pid:0
        };
        that.$request.getEnterprisetype(param).then((res) => {
          if (res.code == 200) {
            that.enterpriseTypeArr1 = res.datas;
          }
        });
      },
			// 企业下级分类
			getEnterprisetype2(pid){
			  let that = this;
			  let param = {
			    pid:pid
			  };
			  that.$request.getEnterprisetype(param).then((res) => {
			    if (res.code == 200) {
			      that.enterpriseTypeArr2 = res.datas;
			    }
			  });
			},
      // 配置信息
      getWebsiteInfo() {
        let that = this;
        that.$request.getWebsiteInfo().then((res) => {
          if (res.code == 200) {
            that.websiteInfo = res.datas;
          }
        });
      },
	  // 判断是否存在招生员
	  getHasRecruit(){
	    let that = this;
	    let param = {
	      recruitcode:that.recruitcode,
	    };
	    that.$request.getHasRecruit(param).then((res) => {
	      if (res.code == 200) {
				that.recruitTxt = '招生老师为：'+res.datas.name;
	      }else{
			  that.recruitTxt = '招生老师不存在';
		  }
	    });
	  },
	  // 判断是否单位
	  getHasEnterprise(){
	    let that = this;
	    let param = { 
	      enterprise_name:that.enterprise_name,
	    };
	    that.$request.getHasEnterprise(param).then((res) => {
	      if (res.code == 200) {
	  				that.enterpriseTxt = '存在单位：'+res.datas.enterprise_name;
	      }else{
	  			  that.enterpriseTxt = '单位不存在';
	  		  }
	    });
	  },
      //选择注册类型
      selectTypeBtn(e) {
        let that = this;
        let type = e.target.dataset.type;
        that.type = type;
      },
      //不同意
      noAgree() {
        let that = this;
        that.$router.push('/login');
      },
      //上一步
      back(e) {
        let that = this;
        that.step = e.target.dataset.step;
        that.stepsList[that.step].step_ative = ''
        if (that.step-- < 0) that.step = 0;
      },
      //下一步
      next(e) {
        let that = this;
        that.step = e.target.dataset.step;
        that.stepsList[1].step_ative = '1';
        if (that.step++ > 3) that.step = 4;
      },
      //填完基本信息后下一步
      baseNext() {
        let that = this;
        if (!that.username) {
          that.$myMessage.warning('用户名不能为空');
          return false;
        } else {
          /* var reg = new RegExp(/^[a-zA-Z0-9_]{6,16}$/); //字符串正则表达式 6到16位（字母，数字，下划线）
          if (!reg.test(that.username)) {
            that.$myMessage.warning('用户名必须包含字母，数字，下划线且6到16位');
            return false;
          } */
			if (that.username.length < 6 || that.username.length > 16) {
			  that.$myMessage.warning('用户名必须大于6位且小于16位');
			  return false;
			}
        }
        if (!that.mobile) {
          that.$myMessage.warning('手机号码不能为空');
          return false;
        } else {
          var res = new RegExp(/^1\d{10}$/);
          if (!res.test(that.mobile)) {
            that.$myMessage.warning('手机号格式错误');
            return false;
          }
        }
        if (!that.code) {
          that.$myMessage.warning('手机验证码不能为空');
          return false;
        }
        if (!that.password) {
          that.$myMessage.warning('密码不能为空');
          return false;
        } else {
          //var regex = new RegExp("(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}");
          /* var regex = /^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,16}$/;
          if (!regex.test(that.password)) {
            that.$myMessage.warning('密码必须同时包含字母、数字且6到16位');
            return false;
          } */
		  
		  if (that.password.length < 6 || that.password.length>16) {
		    that.$myMessage.warning('密必须6到16位');
		    return false;
		  }
        }
        if (!that.repassword) {
          that.$myMessage.warning('确认密码不能为空');
          return false;
        }
        if (that.password != that.repassword) {
          that.$myMessage.warning('两次密码不一致');
          return false;
        }

        // 判断用户名和手机号码是否已被注册了
        let param = {
          username: that.username,
          mobile: that.mobile,
          code: that.code,
          password: that.password,
          repassword: that.repassword,
        };
        that.$request.checkRegisterBase(param).then((res) => {

          if (res.code == 201) {
            that.$myMessage.warning(res.msg);
            return false;
          } else {
            that.stepsList[2].step_ative = '1';
            if (that.step++ > 3) that.step = 4;
          }
        })
      },
      // 企业注册
      perfectContactsNext(){
        let that = this;
        if (that.type == 1) {
          let type = that.type;
          let username = that.username;
          let mobile = that.mobile;
          let code = that.code;
          let pwd = that.password;
          let repwd = that.repassword;
          let enterprise_name = that.enterprise_name;
          let credit_code = that.credit_code;
          let contacts = that.contacts;
          let tel = that.tel;
          let company_tel = that.company_tel;
          let taxpayer_number = that.taxpayer_number;
          let email = that.email;
          let address = that.address;
          let logo = that.logo;
          let license = that.license;
          let bank_deposit = that.bank_deposit;
          let bank_number = that.bank_number;
          let enterprise_type_id1 = that.enterprise_type_id1;
          let enterprise_type_id2 = that.enterprise_type_id2;
					let recruitcode = that.recruitcode;
          if (!that.enterprise_name) {
            that.$myMessage.warning('企业名称不能为空');
            return false;
          }
          /* if (!that.credit_code) {
            that.$myMessage.warning('统一社会信用代码不能为空');
            return false;
          } */
          if (that.enterprise_type_id1 === '') {
            that.$myMessage.warning('请选择企业类型');
            return false;
          }
          if (that.enterprise_type_id2 === '') {
            that.$myMessage.warning('请选择企业二级分类');
            return false;
          }

          if(!that.contacts){
          	that.$myMessage.warning('企业联系人不能为空');
          	return false;
          }
		  if(!that.tel){
		  	that.$myMessage.warning('联系电话不能为空');
		  	return false;
		  }
		  if(!that.company_tel){
		  	that.$myMessage.warning('单位电话不能为空');
		  	return false;
		  }
          if (!that.address) {
            that.$myMessage.warning('企业地址不能为空');
            return false;
          }
          /* if (!that.logo) {
            that.$myMessage.warning('请上传公司logo');
            return false;
          } */
          if (!that.license) {
            that.$myMessage.warning('请上传营业执照');
            return false;
          }
          if(!that.bank_deposit){
          	that.$myMessage.warning('开户行不能为空');
          	return false;
          }
          if (!that.bank_number) {
            that.$myMessage.warning('银行账号不能为空');
            return false;
          }
		
          let param = {
            type,
            username,
            mobile,
            code,
            pwd,
            repwd,
            taxpayer_number,
            credit_code,
            contacts,
			tel,
			company_tel,
            enterprise_name,
            email,
            address,
            logo,
            license,
            bank_deposit,
            bank_number,
            enterprise_type_id1,
            enterprise_type_id2,
						recruitcode,
          };
		  
          that.$request.toRegister(param).then((res) => {
            if (res.code == 200) {
              that.$myMessage.success(res.msg);
              setTimeout(function() {
                that.stepsList[3].step_ative = '1';
                if (that.step++ > 3) that.step = 4;
              }, 2000);
            } else {
              that.$myMessage.warning(res.msg);
              return false;
            }
          })
        }
      },
      perfectNext() {
        let that = this;
        if (that.type == 0) {
          let type = that.type;
          let username = that.username;
          let mobile = that.mobile;
          let code = that.code;
          let pwd = that.password;
          let repwd = that.repassword;
          let name = that.name;
          let sex = that.sex;
          let id_card = that.id_card;
          let enterprise_name = that.enterprise_name;
          let education_cate_id = that.education_cate_id;
          let headimg = that.headimg;
          let id_photo1 = that.id_photo1;
          let id_photo2 = that.id_photo2;
          let diploma_photo = that.diploma_photo;
					let recruitcode = that.recruitcode;
          if (!that.name) {
            that.$myMessage.warning('姓名不能为空');
            return false;
          }
          if (that.sex === '') {
            that.$myMessage.warning('请选择性别');
            return false;
          }
          if (!that.id_card) {
            that.$myMessage.warning('身份证号码不能为空');
            return false;
          }
          // if(!that.enterprise_name){
          // 	that.$myMessage.warning('工作单位不能为空');
          // 	return false;
          // }
          if (!that.education_cate_id) {
            that.$myMessage.warning('请选择您的学历');
            return false;
          }
          if (!that.headimg) {
            that.$myMessage.warning('请上传您的个人照片');
            return false;
          }
          if (!that.id_photo1) {
            that.$myMessage.warning('请上传您的身份证正面');
            return false;
          }
          if (!that.id_photo2) {
            that.$myMessage.warning('请上传您的身份证反面');
            return false;
          }
          if (!that.diploma_photo) {
            that.$myMessage.warning('请上传您的毕业证扫描件');
            return false;
          }
          let param = {
            type,
            username,
            mobile,
            code,
            pwd,
            repwd,
            name,
            sex,
            id_card,
            enterprise_name,
            education_cate_id,
            headimg,
            id_photo1,
            id_photo2,
            diploma_photo,
			recruitcode,
          };
		  
          that.$request.toRegister(param).then((res) => {
            if (res.code == 200) {
              that.$myMessage.success(res.msg);
              setTimeout(function() {
                that.stepsList[3].step_ative = '1';
                if (that.step++ > 3) that.step = 4;
              }, 2000);
            } else {
              that.$myMessage.warning(res.msg);
              return false;
            }
          })
        }

      },
      //获取手机验证码
      getPhoneCode() {
        let that = this;
        // 如果没有输入手机号，则不往下执行
        console.log(that.mobile);
        if (that.mobile == "") {
          that.$myMessage.warning('请输入手机号码');
          return false;
        }

        let mobile = that.mobile;
        let param = {
          mobile,
          type: 'reg', //注册前获取验证码
        };
        that.$request.sendCodeAfter(param).then((res) => {
          if (res.code == 200) {
            // 当验证码发送成功，开始60秒倒计时
            const time = 60;
            if (!this.timer) {
              this.showPhoneCode = false;
              this.count = time;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= time) {
                  this.count -= 1;
                } else {
                  this.showPhoneCode = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          }
        })

      },
      //获取学历
      getEducationCate() {
        let that = this;
        that.$request.getEducationCate({}).then((res) => {
          if (res.code == 200) {
            this.educationCateArr = res.datas;
          }
        })
      },
      //头像上传成功
      handleAvatarSuccess(res, file) {
        let that = this;
        if (res.code == 200) {
          console.log(file.raw);
          that.loading = false;
          that.headimg = res.datas.location;
        } else {
          that.loading = false;
          that.$myMessage.warning(res.msg);
        }
      },
      //身份证上传成功
      handleIdPhoto1Success(res, file) {
        let that = this;
        if (res.code == 200) {
          console.log(file.raw);
          that.loading = false;
          that.id_photo1 = res.datas.location;
        } else {
          that.loading = false;
          that.$myMessage.warning(res.msg);
        }
      },
      //身份证上传成功
      handleIdPhoto2Success(res, file) {
        let that = this;
        if (res.code == 200) {
          console.log(file.raw);
          that.loading = false;
          that.id_photo2 = res.datas.location;
        } else {
          that.loading = false;
          that.$myMessage.warning(res.msg);
        }
      },
      //企业logo
      logoSuccess(res, file) {
        let that = this;
        if (res.code == 200) {
          console.log(file.raw);
          that.loading = false;
          that.logo = res.datas.location;
        } else {
          that.loading = false;
          that.$myMessage.warning(res.msg);
        }
      },
      //营业执照
      licenseSuccess(res, file) {
        let that = this;
        if (res.code == 200) {
          console.log(file.raw);
          that.loading = false;
          that.license = res.datas.location;
        } else {
          that.loading = false;
          that.$myMessage.warning(res.msg);
        }
      },
      //毕业证扫描件上传成功
      handleDiplomaPhotoSuccess(res, file) {
        let that = this;
        if (res.code == 200) {
          console.log(file.raw);
          that.loading = false;
          that.diploma_photo = res.datas.location;
        } else {
          that.loading = false;
          that.$myMessage.warning(res.msg);
        }
      },
      beforeAvatarUpload(file) {
        let that = this;
        that.loading = true;
        const isJPG = file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          that.loading = false;
          this.$message.error('上传图片只能是 jpg、jpeg、png 格式!');
        }
        if (!isLt2M) {
          that.loading = false;
          this.$message.error('上传图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      toLogin() {
        let that = this;
        that.$router.replace('login')
      },

    },
  };
</script>

<style lang="css">

  .login-cont {
    height: 100vh;
    background-color: #F5F6FA;
  }
  .login-logo {
    padding: 1.2rem 5rem;
    display: flex;
    align-items: center;
    background-color: #fff;
  }
  .login-logo-img {
    margin-right: 1.5rem;
    width: 3rem;
    height: 3rem;
  }
  .login-logo-img img,
  .login-form-cont-img img {
    width: 100%;
    height: 100%;
  }
  .login-logo-text {
    font-size: 1.5rem;
    color: #3D3D3D;
  }
  .reg-cont {
    margin: 2rem auto;
    padding-bottom: 1.5rem;
    width: 80%;
    background-color: #fff;
  }
  .reg-cont-tag {
    padding: 1.5rem 0;
    display: flex;
    justify-content: center;
    align-items: stretch;
    border-bottom: 1px solid #D8D8D8;
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
  }
  .reg-cont-tag-item:first-child {
    border-right: 1px solid #D8D8D8;
  }
  .reg-cont-tag-item {
    width: 50%;
  }
  .reg-cont-tag-item-active {
    color: #0074FF;
    text-decoration: underline;
    text-underline-offset: 1.7rem;
  }
  .steps {
    margin: 1.5rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1;
  }
  .steps-item {
    position: relative;
    padding: 1rem 5rem;
    background-color: #F8F8F8;
  }
  .steps-item:first-child::after {
    position: absolute;
    right: -1.6rem;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-left: 1.6rem solid #F8F8F8;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  }
  .steps-item:nth-child(2)::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-left: 1.6rem solid #fff;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  }
  .steps-item:nth-child(2)::after {
    position: absolute;
    right: -1.6rem;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-left: 1.6rem solid #F8F8F8;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  }
  .steps-item:nth-child(3)::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-left: 1.6rem solid #fff;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  }
  .steps-item:nth-child(3)::after {
    position: absolute;
    right: -1.6rem;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-left: 1.6rem solid #F8F8F8;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  }
  .steps-item:last-child::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-left: 1.6rem solid #fff;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  }
  .steps-item-active {
    color: #0074FF;
    background-color: #F3F8FE;
  }
  .steps-item-active:first-child::after,
  .steps-item:nth-child(2)::after,
  .steps-item:nth-child(3)::after {
    position: absolute;
    right: -1.6rem;
    top: 0;
    content: '';
    width: 0;
    height: 0;
    border-left: 1.6rem solid #F3F8FE;
    border-top: 1.6rem solid transparent;
    border-bottom: 1.6rem solid transparent;
  }
  .reg-agreement-title {
    text-align: center;
    font-size: 1.5rem;
  }
  .reg-agreement-text {
    overflow-y: auto;
    margin: 1.5rem 3rem;
    padding: 1rem 2rem;
    height: calc(100vh - 35rem);
    border: 1px solid #D8D8D8;
    font-size: 0.6rem;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #D8D8D8;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, .5);
    border-radius: 10px;
    background-color: rgba(240, 240, 240, .5);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(237, 44, 37, .5);
    background-color: #0074FF;
  }
  .reg-agreement-btn {
    display: flex;
    justify-content: flex-end;
    margin-right: 3rem;
    text-align: center;

  }
  .add-cursor {
    cursor: pointer;
  }
  .reg-agreement-btn>div {
    margin-left: 3rem;
    width: 10rem;
    font-size: 1.2rem;
    color: #fff;
    line-height: 3rem;
  }
  .reg-agreement-btn-disagree {
    background-color: #BCBFCC;
  }
  .reg-agreement-btn-agree {
    background-color: #0074FF;
  }
  .reg-user-info-form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reg-user-info-form-label {
    margin-right: 2rem;
    width: 8rem;
    text-align: right;
    color: #606266;
    font-size: 1rem;
  }
  .recruit-txt{
		width: 10rem;
	  color:red;
  }
  .reg-user-info-form-yzm {
    margin-left: 2rem;
    width: 10%;
    background-color: #0074FF;
    color: #fff;
    border-radius: 0.2rem;
    line-height: 2.5;
    text-align: center;
  }
  .reg-user-info-form-label::before {
    content: '*';
    color: #FA5151;
  }
  .reg-form-cont-input {
    margin: 0.5rem 0;
    background-color: rgb(255, 255, 255);
    height: 2.5rem;
    width: 50%;
    outline: none;
    border: solid 1px #DCDFE6;
    border-radius: 0.2rem;
    text-indent: 4px;
  }
  .reg-form-cont-input.tip{
	  width: calc(50% - 10rem);
  }
  .reg-form-cont-input-yzm {
    margin: 0.5rem 0;
    background-color: rgb(255, 255, 255);
    height: 2.5rem;
    width: 38%;
    outline: none;
    border: solid 1px #DCDFE6;
    border-radius: 0.2rem;
    text-indent: 4px;
  }
  .reg-form-cont-placeholder {
    background-color: rgb(255, 255, 255);
    color: #C0C4CC;
    font-size: 1rem;
  }
  .reg-form-cont-input:focus {
    outline: none;
    border: solid 1px #DCDFE6;
    background-color: rgb(255, 255, 255);
  }
  .reg-user-info-btn {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .reg-user-info-btn>div {
    margin-left: 3rem;
    width: 10rem;
    font-size: 1.2rem;
    line-height: 3rem;
  }
  .reg-back-btn {
    border: 1px solid #DCDFE6;
  }
  .reg-agreement-btn-agree {
    background-color: #0074FF;
    color: #fff;
  }
  .reg-perfect-info {
    overflow-y: auto;
    height: calc(100vh - 30rem);
  }
  .reg-perfect-info-form-label {
    margin-right: 2rem;
    width: 8rem;
    text-align: right;
    color: #606266;
    font-size: 1rem;
  }
  .reg-user-info-form-upbox {
    margin: 0.5rem 0;
    width: 50%;
  }
  .reg-user-info-uploader {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 0.2rem;
    width: 7rem;
    height: 7rem;
    border: 1px dotted #C0C4CC;
    text-align: center;
    line-height: 2.2;
    font-size: 3rem;
    color: #C0C4CC;
    border-radius: 0.2rem;
  }
  .reg-user-info-uploader-img {
    width: 7rem;
    height: 7rem;
  }
  .reg-user-info-master {
    color: #FF5F5A;
    font-size: 0.75rem;
  }
  .reg-perfect-info-btn {
    margin-top: 3rem;
  }
  .reg-submit-text {
    margin: 3rem 0;
    color: #3D3D3D;
    font-size: 1.5rem;
    text-align: center;
  }
  .reg-submit-btn {
    margin: auto;
    width: 10rem;
    font-size: 1.2rem;
    background-color: #0074FF;
    color: #fff;
    text-align: center;
    line-height: 3rem;
  }
  .reg-submit-ercode {
    margin: 2rem 3rem 0;
    padding-top: 2rem;
    padding-bottom: 1rem;
    color: #3D3D3D;
    border-top: solid #D8D8D8 1px;
    text-align: center;
  }
  .reg-submit-ercode-img {
    margin-top: 2rem;
    padding: 0.2rem;
    width: 10rem;
    height: 10rem;
    border: solid 1px #D8D8D8;
  }
  .login-form-cont-copyright {
    position: fixed;
    z-index: 1;
    bottom: 2rem;
    width: 100%;
    font-size: 0.7rem;
    text-align: center;
  }
  .login-form-cont-copyright a {
    text-decoration: none;
    color: #3D3D3D;
  }
</style>
